.sign-in-and-sign-up {
  display: flex;
  justify-content: space-evenly;
  margin: 30px auto;

  @media screen and(max-width: 800px) {
    display: grid;
    justify-content: center;
  }
}
